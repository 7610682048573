interface SortIconProps {
    className?: string
}

export const SortIcon = ({ ...rest }: SortIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.665" height="29.961" viewBox="0 0 26.665 29.961" {...rest}>
            <g id="Group_10111" data-name="Group 10111" transform="translate(0)">
                <path
                    id="線_1301"
                    data-name="線 1301"
                    d="M2.079,29.1A2.079,2.079,0,0,1,0,27.021V2.079a2.079,2.079,0,0,1,4.157,0V27.021A2.079,2.079,0,0,1,2.079,29.1Z"
                    transform="translate(11.254)"
                    fill="currentColor"
                />
                <path
                    id="線_1302"
                    data-name="線 1302"
                    d="M2.078,18.707A2.079,2.079,0,0,1,0,16.629V2.079a2.079,2.079,0,1,1,4.157,0v14.55A2.079,2.079,0,0,1,2.078,18.707Z"
                    transform="translate(23.725 13.794) rotate(45)"
                    fill="currentColor"
                />
                <path
                    id="線_1303"
                    data-name="線 1303"
                    d="M2.079,18.707A2.079,2.079,0,0,1,0,16.629V2.079a2.079,2.079,0,0,1,4.157,0v14.55A2.079,2.079,0,0,1,2.079,18.707Z"
                    transform="translate(0 16.733) rotate(-45)"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
}
