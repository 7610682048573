export const SiteIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.04207 3.93845H16.3633V18.0616H5.04207V3.93845ZM4 19H17.4092V3H4V19Z" fill="white" />
            <path d="M8.94618 7.86214H7.77546V6.80783H8.94747L8.94618 7.86214ZM9.98825 5.86938H6.7334V8.80059H9.98825V5.86938Z" fill="white" />
            <path d="M8.94618 12.4331H7.77546V11.3776H8.94747L8.94618 12.4331ZM9.98825 10.4403H6.7334V13.3715H9.98825V10.4403Z" fill="white" />
            <path d="M13.8935 7.86214H12.7214V6.80783H13.8935V7.86214ZM14.9355 5.86938H11.6807V8.80059H14.9355V5.86938Z" fill="white" />
            <path d="M13.8935 12.4331H12.7214V11.3776H13.8935V12.4331ZM14.9355 10.4403H11.6807V13.3715H14.9355V10.4403Z" fill="white" />
            <path d="M14.1531 17.8282H7.38484V15.6014H14.1544L14.1531 17.8282ZM15.1952 14.663H6.34277V18.7667H15.1952V14.663Z" fill="white" />
            <path d="M11.6244 15.5245H10.3379V17.8417H11.6244V15.5245Z" fill="white" />
        </svg>
    )
}
