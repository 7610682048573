import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability'
import { UserLevel, UserPermission } from 'constants/enum'
import { Account } from 'types/Account'
import { Company } from 'types/Company'
import { Vendor } from 'types/Vendor'
import { Site } from 'types/Site'
import { Scope } from 'types/Scope'
import { MaintenanceCompany } from 'types/MaintenanceCompany'

type Actions = 'manage' | 'view' | 'select' | 'create' | 'modify'
export type Subjects =
    | 'all'
    | 'noAuthorization'
    | 'Vendor'
    | Vendor
    | 'Company'
    | Company
    | 'Site'
    | Site
    | 'Account'
    | Account
    | 'Scope'
    | Scope
    | 'MaintenanceCompany'
    | MaintenanceCompany
export type IAppAbility = Ability<[Actions, Subjects]>
const AppAbility = Ability as AbilityClass<IAppAbility>
const ability = new AppAbility(undefined, {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object: any) => object!.__typename,
})

export const updateAbility = (scopes: string[]) => {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility)

    scopes.forEach((scope) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let [level, permission, vendorId, companyId, siteId] = scope.split('.')
        level = scope.length ? level : UserLevel.Undefined

        switch (level) {
            case UserLevel.Admin:
                if (permission === UserPermission.Full || permission === UserPermission.ReadWrite) {
                    can('manage', 'all')
                } else if (permission === UserPermission.Read) {
                    can(['view', 'select'], 'all')
                } else if (permission === UserPermission.Demo) {
                    can('view', ['noAuthorization'])
                    break
                }
                cannot('view', 'noAuthorization')
                break
            case UserLevel.Vendor:
                can('view', ['noAuthorization'])
                break
            case UserLevel.Company:
                can('view', ['noAuthorization'])
                break
            case UserLevel.MaintenanceCompany:
                can('view', ['noAuthorization'])
                break
            case UserLevel.Site:
                can('view', ['noAuthorization'])
                break
            case UserLevel.Undefined:
                can('view', ['noAuthorization'])
                break
            default:
                break
        }
    })

    ability.update(rules)
}

export default ability
