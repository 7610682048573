interface IProps {
    className?: string
}

export const ExpandMoreIcon = ({ ...rest }: IProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="12" viewBox="0 0 13.5 12" {...rest}>
            <path id="Polygon_191" data-name="Polygon 191" d="M6.75,0,13.5,12H0Z" transform="translate(13.5 12) rotate(180)" fill="#b0b0b0" />
        </svg>
    )
}
