import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface VendorFilter {
    keyword: string
}

interface VendorState {
    filter: VendorFilter
}

const initialState: VendorState = {
    filter: {
        keyword: '',
    },
}

const vendorSlice = createSlice({
    name: 'ppa-operators',
    initialState: initialState,
    reducers: {
        setVendorFilter(state, action: PayloadAction<Partial<VendorFilter>>) {
            state.filter = { ...state.filter, ...action.payload }
        },
    },
})

const { actions, reducer } = vendorSlice
export const { setVendorFilter } = actions
export default reducer
