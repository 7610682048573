import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OPTION_ALL } from 'constants/common'

export interface MaintenanceCompanyFilter {
    keyword: string
    vendorId: string
    vendorKeyword: string
}

interface MaintenanceCompanyState {
    filter: MaintenanceCompanyFilter
}

const initialState: MaintenanceCompanyState = {
    filter: {
        keyword: '',
        vendorId: OPTION_ALL.value,
        vendorKeyword: '',
    },
}

const maintenanceCompanySlice = createSlice({
    name: 'maintenance-company',
    initialState: initialState,
    reducers: {
        setMaintenanceCompanyFilter(state, action: PayloadAction<Partial<MaintenanceCompanyFilter>>) {
            state.filter = { ...state.filter, ...action.payload }
        },
    },
})

const { actions, reducer } = maintenanceCompanySlice
export const { setMaintenanceCompanyFilter } = actions
export default reducer
