import clsx from 'clsx'
import { startsWith } from 'lodash'
import { Link, useLocation } from 'react-router-dom'

import { PATH_NAME } from 'constants/index'
import { useAppAbility } from 'casl/Can'
import { Subjects } from 'casl/ability'

import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { CompanyIcon, MaintenanceIcon, OperatorIcon, SiteIcon } from 'components/Icon'

type AppMenuItems = {
    name: string
    icon: Object
    link: string
    subject: Subjects
}

export default function AppMenuSetting() {
    const classes = useStyles()
    const location = useLocation()
    const ability = useAppAbility()

    const appMenuItems: AppMenuItems[] = [
        {
            name: 'サイト',
            icon: <SiteIcon />,
            link: PATH_NAME.SITE.INDEX,
            subject: 'Site',
        },
        {
            name: '利用企業',
            icon: <CompanyIcon />,
            link: PATH_NAME.COMPANY.INDEX,
            subject: 'Company',
        },
        {
            name: '事業者',
            icon: <OperatorIcon />,
            link: PATH_NAME.PPA_OPERATOR.INDEX,
            subject: 'Vendor',
        },
        {
            name: 'メンテナンス事業者',
            icon: <MaintenanceIcon />,
            link: PATH_NAME.MAINTENANCE_COMPANY.INDEX,
            subject: 'MaintenanceCompany',
        },
        {
            name: '利用者',
            icon: <div className={classes.userIcon}></div>,
            link: PATH_NAME.USER.INDEX,
            subject: 'Account',
        },
    ]

    const handleSplitPathName = (name: string) => {
        return '/' + name.split('/')[1]
    }

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="secondary mailbox folders" className={classes.nav}>
                {appMenuItems
                    .filter((menuItem: AppMenuItems) => ability.can('view', menuItem.subject))
                    .map((item: AppMenuItems, index: number) => (
                        <ListItem
                            key={index}
                            button
                            className={clsx(classes.item, classes.siteLink)}
                            component={Link}
                            to={item.link}
                            selected={startsWith(item.link, handleSplitPathName(location.pathname))}
                        >
                            {item.icon}
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
            </List>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 184,
        backgroundColor: theme.palette.green.dark,
        position: 'fixed',
        height: '100%',
        zIndex: 1,
    },
    nav: {
        paddingTop: 40,
    },
    item: {
        minHeight: 40,
        color: theme.palette.green.dark,
        backgroundRepeat: 'no-repeat',
        width: 22,
        height: 22,
        borderBottom: `1px solid ${theme.palette.green.light}`,
        '& .MuiTypography-body1': {
            fontFamily: 'Hiragino Sans, sans-serif',
            fontSize: '15px',
            fontWeight: '400 !important',
            letterSpacing: 0,
            lineHeight: '40px',
        },
        '& svg': {
            marginRight: 8,
        },
        '&.Mui-selected': {
            background: 'unset',
            '& svg path': {
                fill: theme.palette.green.main,
            },
        },
        '&:last-of-type': {
            '&.Mui-selected': {
                '& > div:first-of-type': {
                    backgroundImage: 'url("/assets/images/user-setting-active.svg")',
                },
            },
        },
        '&.Mui-selected .MuiTypography-body1': {
            fontWeight: '400 !important',
            color: theme.palette.green.main,
        },
        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#E6E6E6',
        },
        '& .MuiListItemText-root': {
            margin: 0,
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: theme.palette.green.light,
        },
    },
    userIcon: {
        marginRight: 8,
        backgroundImage: 'url("/assets/images/user-setting.svg")',
        backgroundRepeat: 'no-repeat',
        width: 22,
        height: 22,
    },
    siteLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#FFF',
        width: '100%',
        cursor: 'pointer',
        padding: '9px 8px',
    },
}))
