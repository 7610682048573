export enum UserPermission {
    Full = 'full',
    Execute = 'execute',
    ReadWrite = 'readwrite',
    Read = 'read',
    Demo = 'demo',
}

export enum UserLevel {
    Admin = 'admin',
    Vendor = 'vendor',
    Company = 'company',
    MaintenanceCompany = 'maintenanceCompany',
    Site = 'site',
    Undefined = 'undefined',
}

export enum LevelValue {
    Admin = 99,
    Vendor = 3,
    Company = 2,
    Site = 1,
    Undefined = 0,
}

export enum AreaType {
    Hokkaido = 'hokkaido',
    Tohoku = 'tohoku',
    Tokyo = 'tokyo',
    Chubu = 'chubu',
    Hokuriku = 'hokuriku',
    Kansai = 'kansai',
    Chugoku = 'chugoku',
    Shikoku = 'shikoku',
    Kyushu = 'kyushu',
    Okinawa = 'okinawa',
}

export enum HttpStatusCode {
    Unauthorized = 401,
}

export enum SurplusBuyerIsFit {
    NonFit = 0,
    Fit = 1,
}

export enum SurplusBuyerRounding {
    Floor = 1,
    Ceil = 2,
    Round = 3,
}

export enum InvoiceNumberStatus {
    Yes = 1,
    No = 2,
}

export enum OutputSuppression {
    Off = 0,
    On = 1,
}

export enum CheckboxValue {
    Off = 0,
    On = 1,
}

export enum RadioValue {
    Off = 0,
    On = 1,
}

export enum AmountStatus {
    taxIncluded = 1,
    taxExcluded = 2,
}

export enum RegisterModeValue {
    Admin = 1,
    PPAUser = 2,
    AirUser = 3,
    AdvancedPermissionSetting = 0,
}
// Set default vendorID vppj when user not choose user serviceUsed (this only temporary)
export enum VendorValue {
    VPPJ = 'vppj',
}

export enum TypeOfComponent {
    Sites = 'sites',
    Users = 'users',
    Companies = 'companies',
    PPAOperators = 'ppa-operators',
    MaintenanceCompany = 'maintenance-company',
}

export enum MaintenanceCompanyTarget {
    AllChecked = '全企業',
}

export enum CheckedAllStatus {
    UnCheckedAll = 0,
    CheckedAll = 1,
}
export enum AccountType {
    Normal = 1,
    Temporarily = 2,
}

export enum Order {
    Asc = 'asc',
    Desc = 'desc',
}
