import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CompanyFilter {
    keyword: string
}

interface CompanyState {
    filter: CompanyFilter
}

const initialState: CompanyState = {
    filter: {
        keyword: '',
    },
}

const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        setCompanyFilter(state, action: PayloadAction<Partial<CompanyFilter>>) {
            state.filter = { ...state.filter, ...action.payload }
        },
    },
})

const { actions, reducer } = companySlice
export const { setCompanyFilter } = actions
export default reducer
