interface IProps {
    className?: string
}

export const ArrowRightIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M16 12L0 24L6.53752e-07 0L16 12Z" fill="#7A7979" />
        </svg>
    )
}
