import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Menu, MenuItem, Box } from '@material-ui/core'
import { accountNameSelector } from 'selectors/authSelector'
import { useSelector } from 'react-redux'
import { PATH_NAME } from 'constants/index'
import EnvLabel from './EnvLabel'
import { AppEnv } from 'constants/env'

const Header: React.FC = () => {
    const classes = useStyles()
    const [anchorAvatar, setAnchorAvatar] = useState(null)
    const myPage = `${process.env.REACT_APP_AUTH_API_URL}/mypage`
    const accountName = useSelector(accountNameSelector)

    const handleAvatarClick = (event: any) => {
        setAnchorAvatar(event.currentTarget)
    }

    const handleAvatarClose = () => {
        setAnchorAvatar(null)
    }

    const handleRedirect = () => {
        setAnchorAvatar(null)
        window.open(myPage, 'settingApp')
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.text}>サイト管理アプリ</Typography>
            <Box display="flex" alignItems="center">
                <EnvLabel env={process.env.REACT_APP_ENV as AppEnv} />
                <Typography className={classes.accountName} onClick={handleAvatarClick}>
                    {accountName}
                    <img src="/assets/images/arrow-down.svg" alt="arrow-down" style={{ marginLeft: 10 }} />
                </Typography>
            </Box>
            <Menu id="simple-menu2" anchorEl={anchorAvatar} keepMounted open={Boolean(anchorAvatar)} onClose={handleAvatarClose} className={classes.menuLogin}>
                <MenuItem className={classes.itemLogin} style={{ borderBottom: '1px solid #e3e3e3' }} onClick={handleRedirect}>
                    アカウント設定
                </MenuItem>
                <MenuItem className={classes.itemLogin} onClick={handleAvatarClose} component={Link} to={PATH_NAME.LOGOUT}>
                    ログアウト
                </MenuItem>
            </Menu>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        height: 40,
        boxShadow: '0 4px 4px #0000001A',
        background: '#76A2AA',
        zIndex: 99,
    },
    text: {
        marginLeft: 18,
        fontFamily: 'Hiragino Sans, sans-serif',
        fontSize: 12,
        color: '#FFF',
    },
    avatar: {
        marginRight: 40,
        marginLeft: 22,
        width: 26,
        height: 26,
        cursor: 'pointer',
    },
    menuLogin: {
        boxShadow: '0 0 2px #e3e3e3',
        '& ul': {
            padding: 0,
        },
        '& .MuiPaper-elevation8': {
            boxShadow: 'none',
        },
        '& .MuiListItem-root.Mui-focusVisible': {
            backgroundColor: '#FAFAFA',
        },
        '& .MuiPaper-root': {
            top: '40px !important',
            left: 'unset !important',
            right: '40px !important',
            borderRadius: 0,
        },
    },
    itemLogin: {
        fontSize: '12px',
        fontFamily: 'Hiragino Sans, sans-serif',
        height: '35px',
        width: '200px',
        background: '#FAFAFA',
        boxShadow: 'none',
        borderBottom: 'none',
        '&.MuiListItem-button:hover': {
            backgroundColor: '#fafafa',
        },
    },
    accountName: {
        color: '#4A4A4A',
        marginRight: 30,
        fontFamily: 'Hiragino Sans, sans-serif',
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 'unset',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.6,
        },
    },
}))

export default Header
