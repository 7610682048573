interface IProps {
    className?: string
}

export const DeleteIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M5.2531 5.20721H6.31637V13.5387H5.2531V5.20721ZM7.37964 5.20721H8.44292V13.5387H7.37964V5.20721ZM9.50619 5.20721H10.5695V13.5387H9.50619V5.20721ZM1 2.08288H14.8226V3.12432H1V2.08288ZM10.5695 2.08288H9.50619V1.56216C9.50619 1.24973 9.29354 1.04144 8.97456 1.04144H6.84801C6.52903 1.04144 6.31637 1.24973 6.31637 1.56216V2.08288H5.2531V1.56216C5.2531 0.676937 5.94422 0 6.84801 0H8.97456C9.87834 0 10.5695 0.676937 10.5695 1.56216V2.08288Z"
                fill="currentColor"
            />
            <path
                d="M11.0532 16H4.77025C3.93252 16 3.19951 15.3506 3.09479 14.5512L2.15234 2.56122L3.19951 2.4613L4.14195 14.4513C4.14195 14.7511 4.4561 15.0009 4.77025 15.0009H11.0532C11.3674 15.0009 11.6292 14.7511 11.6815 14.4513L12.624 2.4613L13.6711 2.56122L12.7287 14.5512C12.624 15.3506 11.891 16 11.0532 16Z"
                fill="currentColor"
            />
        </svg>
    )
}
