import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { IAppAbility } from 'casl/ability'
import { useSnackbar } from 'notistack'
import { useAppAbility } from 'casl/Can'
import { PATH_NAME } from 'constants/pathName'

// import { IAppAbilityManagement } from 'caslManagement/ability'

type IProps = {
    hasAbility?: (ability: any) => boolean | undefined
}

const AbilityRoute: FC<IProps> = ({ children, hasAbility }) => {
    const history = useHistory()
    const ability = useAppAbility()
    const { enqueueSnackbar } = useSnackbar()
    const [isAuthorized, setIsAuthorized] = useState(false)

    // Redirect base on authorization
    const getSettingAppLink = ability.can('view', 'Vendor')
        ? PATH_NAME.PPA_OPERATOR.INDEX
        : ability.can('view', 'Company')
        ? PATH_NAME.COMPANY.INDEX
        : ability.can('view', 'Site')
        ? PATH_NAME.SITE.INDEX
        : ability.can('view', 'Account')
        ? PATH_NAME.USER.INDEX
        : PATH_NAME.NO_AUTHORIZATION

    useEffect(() => {
        if (hasAbility && !hasAbility(ability)) {
            enqueueSnackbar('権限がありません', { variant: 'error' })
            history.length > 2 ? history.goBack() : history.push(getSettingAppLink)
            return
        }
        setIsAuthorized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isAuthorized) return null

    return <>{children}</>
}

export default AbilityRoute
