export const PATH_NAME = {
    // COMMON PATH
    HOME: '/',
    LOGIN: '/login',
    LOGOUT: '/logout',
    // END COMMON PATH
    // WARNING: Setting App was used another domain.
    PPA_OPERATOR: {
        INDEX: '/ppa-operators',
        DETAIL: '/ppa-operators/:id',
        CREATE: '/ppa-operators/create',
    },
    COMPANY: {
        INDEX: '/companies',
        CREATE: '/companies/create',
        DETAIL: '/companies/:id',
        INFO: '/companies-info',
    },
    SITE: {
        INDEX: '/sites',
        MODIFY: '/sites/create',
        MODIFY_DETAIL: '/sites/:id',
        EQUIPMENT_MODIFY: '/sites/:id/equipment/:equipmentId',
    },
    USER: {
        INDEX: '/users',
        DETAIL: '/users/:id',
        CREATE: '/users/create',
    },
    NO_AUTHORIZATION: '/no-authorization',
    MAINTENANCE_COMPANY: {
        INDEX: '/maintenance-company',
        CREATE: '/maintenance-company/create',
        DETAIL: '/maintenance-company/:id',
    },
}
