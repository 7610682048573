import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Header from './Header'
import AppMenuSetting from './Slidebar/AppMenuSetting'

const BaseLayout: FC = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Header />
            <AppMenuSetting />
            <div className={classes.main}>{children}</div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Hiragino Sans, sans-serif',
        background: '#EFEFEF',
        minHeight: '100vh',
    },
    main: {
        marginTop: 40,
        marginLeft: 184,
        minHeight: 'calc(100vh - 65px)',
        width: 'calc(100% - 184px)',
    },
}))

export default BaseLayout
