interface IProps {
    className?: string
    onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
}

export const CloseIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect y="1.15625" width="1.63649" height="9.81896" rx="0.818247" transform="rotate(-45 0 1.15625)" fill="#4A4A4A" />
            <rect x="6.94336" width="1.63649" height="9.81896" rx="0.818247" transform="rotate(45 6.94336 0)" fill="#4A4A4A" />
        </svg>
    )
}
