import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OPTION_ALL } from 'constants/common'

export interface UserFilter {
    companyId: string
    companyKeyword: string
    level: string
    keyword: string
}

interface UserState {
    filter: UserFilter
}

const initialState: UserState = {
    filter: {
        companyId: OPTION_ALL.value,
        companyKeyword: '',
        level: OPTION_ALL.value,
        keyword: '',
    },
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUserFilter(state, action: PayloadAction<Partial<UserFilter>>) {
            state.filter = { ...state.filter, ...action.payload }
        },
    },
})

const { actions, reducer } = userSlice
export const { setUserFilter } = actions
export default reducer
