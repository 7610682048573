import React, { useEffect, useState } from 'react'
import Amplify from 'aws-amplify'
import { AMPLIFY_CONFIG } from 'constants/index'
import { useSelector } from 'react-redux'
import { updateAbility } from 'casl/ability'
import Routes from 'routes/Routes'
import useAuth from 'hooks/useAuth'
import { useAppDispatch } from 'app/hooks'
import { getAccountScopes } from 'slices'
import { accountScopesSelector } from 'selectors'
import { LoadingBackDrop } from 'components/Common'

Amplify.configure(AMPLIFY_CONFIG)

const App: React.FC = () => {
    const dispatch = useAppDispatch()
    const scopes = useSelector(accountScopesSelector)
    const { currentUserId, isLoggingIn } = useAuth()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        document.title = 'サイト管理アプリ'
    }, [])

    useEffect(() => {
        ;(async () => {
            if (!currentUserId) return
            try {
                setIsLoading(true)
                await dispatch(getAccountScopes(currentUserId))
            } catch (error) {
                console.log('Failed to fetch account scopes')
            }
            setIsLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId])

    useEffect(() => {
        updateAbility(scopes) // Setting app
    }, [scopes])

    if (isLoggingIn || isLoading) return <LoadingBackDrop />

    return <Routes />
}

export default App
