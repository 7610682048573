export const CompanyIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.43692 5.61399H14.3905V17.8862H7.43692V5.61399ZM6.79688 18.5001H15.0329V5H6.79688V18.5001Z" fill="white" />
            <path d="M9.83469 8.22113H9.11563V7.53135H9.83548L9.83469 8.22113ZM10.4747 6.91736H8.47559V8.83512H10.4747V6.91736Z" fill="white" />
            <path d="M9.83469 11.214H9.11563V10.5242H9.83548L9.83469 11.214ZM10.4747 9.91022H8.47559V11.828H10.4747V9.91022Z" fill="white" />
            <path d="M4.95969 13.1304H4.24063V12.4406H4.96048L4.95969 13.1304ZM5.59973 11.8267H3.60059V13.7444H5.59973V11.8267Z" fill="white" />
            <path d="M4.24063 14.9725H4.96048V17.8871H4.24063V14.9725ZM3.60059 18.5003H5.59973V14.3585H3.60059V18.5003Z" fill="white" />
            <path d="M17.6042 13.1304H16.8852V12.4406H17.605L17.6042 13.1304ZM18.2443 11.8267H16.2451V13.7444H18.2443V11.8267Z" fill="white" />
            <path d="M16.8852 14.9725H17.605V17.8871H16.8852V14.9725ZM16.2451 18.5003H18.2443V14.3585H16.2451V18.5003Z" fill="white" />
            <path d="M9.83469 14.204H9.11563V13.5135H9.83548L9.83469 14.204ZM10.4747 12.9003H8.47559V14.818H10.4747V12.9003Z" fill="white" />
            <path d="M12.8767 8.22113H12.1568V7.53135H12.8767V8.22113ZM13.5167 6.91736H11.5176V8.83512H13.5167V6.91736Z" fill="white" />
            <path d="M12.8767 11.214H12.1568V10.5242H12.8767V11.214ZM13.5167 9.91022H11.5176V11.828H13.5167V9.91022Z" fill="white" />
            <path d="M2.64004 10.5242H6.71735V17.8875H2.64004V10.5242ZM2 18.5007H7.35739V9.91022H2V18.5007Z" fill="white" />
            <path d="M12.8767 14.204H12.1568V13.5135H12.8767V14.204ZM13.5167 12.9003H11.5176V14.818H13.5167V12.9003Z" fill="white" />
            <path d="M13.0354 17.7337H8.87832V16.2768H13.0362L13.0354 17.7337ZM13.6755 15.6628H8.23828V18.3477H13.6755V15.6628Z" fill="white" />
            <path d="M15.2826 10.5242H19.3599V17.8875H15.2826V10.5242ZM14.6426 18.5007H20V9.91022H14.6426V18.5007Z" fill="white" />
            <path d="M11.4845 16.2264H10.6943V17.7425H11.4845V16.2264Z" fill="white" />
        </svg>
    )
}
