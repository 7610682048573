interface IProps {
    className?: string
}

export const ExpandLessIcon = ({ ...rest }: IProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13.5" viewBox="0 0 12 13.5" {...rest}>
            <path id="Polygon_191" data-name="Polygon 191" d="M6.75,0,13.5,12H0Z" transform="translate(12) rotate(90)" fill="#b0b0b0" />
        </svg>
    )
}
