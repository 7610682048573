import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Site, ServiceUsedValue } from 'types'
import { OPTION_ALL } from 'constants/common'

export interface SiteFilter {
    companyId: string
    companyKeyword: string
    keyword: string
    services: ServiceUsedValue[]
}

interface SiteState {
    sites: Site[]
    filter: SiteFilter
}

const initialState: SiteState = {
    sites: [],
    filter: {
        companyId: OPTION_ALL.value,
        companyKeyword: '',
        keyword: '',
        services: [OPTION_ALL.value],
    },
}

const sites = createSlice({
    name: 'site',
    initialState: initialState,
    reducers: {
        setSites: (state, action: PayloadAction<Site[]>) => {
            state.sites = action.payload
        },
        addSite: (state, action: PayloadAction<Site>) => {
            state.sites = [...state.sites, action.payload]
        },
        editSite: (state, action: PayloadAction<Site>) => {
            state.sites = state.sites.map((item) => (item.id === action.payload.id ? action.payload : item))
        },
        removeSite: (state, action: PayloadAction<string>) => {
            state.sites = state.sites.filter((item) => item.id !== action.payload)
        },
        setFilterSite: (state, action: PayloadAction<Partial<SiteFilter>>) => {
            state.filter = { ...state.filter, ...action.payload }
        },
    },
})

const { actions, reducer } = sites
export const { setSites, addSite, editSite, removeSite, setFilterSite } = actions
export default reducer
