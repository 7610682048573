import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from 'slices/authSlice'
import filterReducer from 'slices/filterSlice'
import siteReducer from 'slices/siteSlice'
import userReducer from 'slices/userSlice'
import vendorReducer from 'slices/vendorSlice'
import companyReducer from 'slices/companySlice'
import maintenanceCompanyReducer from 'slices/maintenanceCompanySlice'

const rootReducer = {
    auth: authReducer,
    filter: filterReducer,
    site: siteReducer,
    user: userReducer,
    vendor: vendorReducer,
    company: companyReducer,
    maintenanceCompany: maintenanceCompanyReducer,
}

const store = configureStore({
    reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export default store
